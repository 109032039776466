/**
 * Mesh lighting page
 *
 * @author Julian Ebert
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import styled from 'styled-components';

import { AppDetails } from '../components/AppDetails';
import { Contact } from '../components/Contact';
import { Container } from '../components/Container';
import { Feature } from '../components/Feature';
import { InterfacesContainer } from '../components/InterfacesContainer';
import { MatterFeaturesContainer } from '../components/MatterFeaturesContainer';
import { MeshlightingFooter } from '../components/MeshlightingFooter';
import { Page } from '../components/Page';
import { Product } from '../components/Product';
import { ProductsContainer } from '../components/ProductsContainer';
import { ThreadDetails } from '../components/ThreadDetails';
import { HeaderH2, HeaderH4, Subtitle, Title } from '../components/Typography';
import { IndexLayout } from '../layouts';
import lightingColors from '../styles/lighting';

import BulletPoint from '../svgs/bullet-point.svg';
import Feature3Icon from '../svgs/matter-feature-communication.svg';
import Feature4Icon from '../svgs/matter-feature-connection.svg';
import Feature1Icon from '../svgs/matter-feature-handshake.svg';
import Feature2Icon from '../svgs/matter-feature-open.svg';
import MatterLogo from '../svgs/matter-logo-white.svg';
import num1 from '../svgs/num1.svg';
import num2 from '../svgs/num2.svg';
import num3 from '../svgs/num3.svg';
import num4 from '../svgs/num4.svg';
import num5 from '../svgs/num5.svg';
import ThreadLogo from '../svgs/thread-logo-white.svg';

const StyledLi = styled.div({
	display: 'flex',
	flexDirection: 'row',
	marginTop: '1rem',
});

const StyledUl = styled.div({
	marginTop: '0rem',
});

const StyledListText = styled.div({
	marginLeft: '1rem',
});

const StyledA = styled.a({
	'&.anchor': {
		display: 'block',
		position: 'relative',
		top: '-8em',
		visibility: 'hidden',
	},
});

const IndexPage: React.FC = () => {
	const { t } = useTranslation('matter');

	return (
		<IndexLayout>
			<Page>
				<Container>
					<div style={{ display: 'flex' }}>
						<Title>
							<HeaderH2>
								Matter & Thread - <br />
								{t('matter.slogan')}
								<HeaderH2 style={{ display: 'inline', color: lightingColors.highlight.orange }}>
									.
								</HeaderH2>
							</HeaderH2>
							<Subtitle>{t('matter.slogan.subtitle')}</Subtitle>
						</Title>
					</div>
				</Container>

				<Container>
					<StyledA className="anchor" id="Products" href="#Products" />
					<ProductsContainer>
						<Product
							imgWidth={'16rem'}
							image={MatterLogo}
							details={t('matter.product.overview.matter.description')}
						/>
						<Product
							imgWidth={'16rem'}
							image={ThreadLogo}
							details={t('matter.product.overview.thread.description')}
						/>
					</ProductsContainer>
				</Container>
			</Page>

			<Page sectionBackground="darkBackground">
				<Container>
					<InterfacesContainer title={t('matter.interfaces.title')} />
				</Container>
			</Page>

			<Page sectionBackground="lightBackground">
				<Container>
					<MatterFeaturesContainer title={t('matter.features.title')}>
						<Feature
							image={Feature1Icon}
							title={t('matter.feature.1.title')}
							details={t('matter.feature.1.description')}
						/>
						<Feature
							image={Feature2Icon}
							title={t('matter.feature.2.title')}
							details={t('matter.feature.2.description')}
						/>
						<Feature
							image={Feature3Icon}
							title={t('matter.feature.3.title')}
							details={t('matter.feature.3.description')}
						/>
						<Feature
							image={Feature4Icon}
							title={t('matter.feature.4.title')}
							details={t('matter.feature.4.description')}
						/>
					</MatterFeaturesContainer>
				</Container>
			</Page>

			<Page sectionBackground="darkBackground">
				<Container>
					<ThreadDetails
						title={t('matter.thread.title')}
						//description={t('matter.thread.description')}
						detailsLeft={
							<StyledUl>
								<StyledLi>
									<div>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<img src={num1} alt="bullet point" />
											<HeaderH4
												style={{ display: 'inline', paddingLeft: '1rem', paddingBottom: '4px' }}
											>
												{t('matter.thread.feature.1.title')}
											</HeaderH4>
										</div>
										<p>{t('matter.thread.feature.1.description')}</p>
									</div>
								</StyledLi>
								<StyledLi>
									<div>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<img src={num2} alt="bullet point" />
											<HeaderH4
												style={{ display: 'inline', paddingLeft: '1rem', paddingBottom: '4px' }}
											>
												{t('matter.thread.feature.2.title')}
											</HeaderH4>
										</div>
										<p>{t('matter.thread.feature.2.description')}</p>
									</div>
								</StyledLi>
								<StyledLi>
									<div>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<img src={num3} alt="bullet point" />
											<HeaderH4
												style={{ display: 'inline', paddingLeft: '1rem', paddingBottom: '4px' }}
											>
												{t('matter.thread.feature.3.title')}
											</HeaderH4>
										</div>
										<p>{t('matter.thread.feature.3.description')}</p>
									</div>
								</StyledLi>
							</StyledUl>
						}
						detailsRight={
							<StyledUl>
								<StyledLi>
									<div>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<img src={num4} alt="bullet point" />
											<HeaderH4
												style={{ display: 'inline', paddingLeft: '1rem', paddingBottom: '4px' }}
											>
												{t('matter.thread.feature.4.title')}
											</HeaderH4>
										</div>
										<p>{t('matter.thread.feature.4.description')}</p>
									</div>
								</StyledLi>
								<StyledLi>
									<div>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<img src={num5} alt="bullet point" />
											<HeaderH4
												style={{ display: 'inline', paddingLeft: '1rem', paddingBottom: '4px' }}
											>
												{t('matter.thread.feature.5.title')}
											</HeaderH4>
										</div>
										<p>{t('matter.thread.feature.5.description')}</p>
									</div>
								</StyledLi>
							</StyledUl>
						}
					>
						<div style={{ width: '100%', flex: 1 }}>
							<StaticImage src="../content/matter-room.jpg" alt="Matter Mesh Network" />
						</div>
					</ThreadDetails>
				</Container>
			</Page>

			<Page sectionBackground="lightBackground">
				<Container>
					<AppDetails
						title={t('matter.product.app.meshthings')}
						description={t('matter.product.app.meshthings.description')}
						details={
							<StyledUl>
								<StyledLi>
									<img src={BulletPoint} alt="bullet point" />
									<StyledListText>{t('product.app.meshthings.detail1')}</StyledListText>
								</StyledLi>
								<StyledLi>
									<img src={BulletPoint} alt="bullet point" />
									<StyledListText>{t('product.app.meshthings.detail2')}</StyledListText>
								</StyledLi>
								<StyledLi>
									<img src={BulletPoint} alt="bullet point" />
									<StyledListText>{t('product.app.meshthings.detail3')}</StyledListText>
								</StyledLi>
								<StyledLi>
									<img src={BulletPoint} alt="bullet point" />
									<StyledListText>{t('product.app.meshthings.detail4')}</StyledListText>
								</StyledLi>
							</StyledUl>
						}
					>
						<div style={{ width: '100%', flex: 1 }}>
							<StaticImage src="../content/meshapp-update.png" alt="Meshthings App" />
						</div>
					</AppDetails>
				</Container>
			</Page>

			<Page sectionBackground="darkBackground" id="Contact">
				<Container>
					<Contact
						title={t('contact.title')}
						text={t('contact.description')}
						buttonText={t('contact.button')}
						url={'mailto:info@thingos.io'}
					/>
				</Container>
			</Page>

			<Page sectionBackground="lightBackground">
				<Container>
					<MeshlightingFooter />
				</Container>
			</Page>
		</IndexLayout>
	);
};

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

export default IndexPage;
