import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import styled from 'styled-components';

import { Button } from './Button';
import { Markdown } from './Markdown';
import { HeaderH4 } from './Typography';

const FeatureLayout = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	marginBottom: '1rem',
	marginTop: '1rem',
	'@media screen and (max-width: 600px)': {
		marginTop: '1rem',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
});

const TextLayout = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	marginLeft: '4rem',
	'@media screen and (max-width: 600px)': {
		marginBottom: '1rem',
		marginLeft: '0rem',
	},
});

const AnchorLinkStyled = styled(AnchorLink)({
	textDecoration: 'none',
	':hover': {
		textDecoration: 'none',
	},
});

const StyledImage = styled.img({
	selfAlign: 'flex-start',
	height: '8rem',
});

interface FeatureProps {
	image: any;
	title: string;
	details: string;
	url?: string;
}

export const Feature: React.FC<FeatureProps> = props => {
	const { image, title, details, url } = props;
	const { t } = useTranslation();

	return (
		<FeatureLayout>
			{/* <Img fixed={image}></Img> */}
			<StyledImage src={image} alt="Feature Image" />
			<TextLayout>
				<HeaderH4>{title}</HeaderH4>
				<p style={{ flex: 1 }}>
					<Markdown markdown={details} />
				</p>
				{url != null && (
					<AnchorLinkStyled to={url}>
						<Button style={{ marginTop: '2rem' }}>{t('button.more')}</Button>
					</AnchorLinkStyled>
				)}
			</TextLayout>
		</FeatureLayout>
	);
};
