import * as React from 'react';
import styled from 'styled-components';

import { Body, HeaderH3 } from './Typography';

import seperatorIndicator from '../svgs/seperator-indicator.svg';

const ThreadDetailsContainer = styled.div`
	margin-top: 6rem;
	margin-bottom: 6rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@media screen and (max-width: 600px) {
		flex-wrap: wrap;
		justify-content: center;
	}
`;

const HeaderLayout = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	marginBottom: '2rem',
	width: '100%',

	'@media screen and (max-width: 600px)': {
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
	},
});

const ThreadDetailsRow = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	width: '100%',

	'@media(max-width: 600px)': {
		flexDirection: 'column',
	},
});

const ThreadDetailsText = styled.div({
	display: 'flex',
	flexDirection: 'column',
	color: '#fff',
	'&>:not(:last-child)': {
		marginRight: '1rem',
	},
	'&>(:last-child)': {
		marginLeft: '1rem',
	},
	marginBottom: '2rem',
	'@media screen and (max-width: 600px)': {
		marginBottom: '0rem',
	},
});

const StyledImage = styled.div({
	display: 'flex',
	width: '100%',
	alignSelf: 'center',
	marginBottom: '0rem',
	overflow: 'hidden',
	borderRadius: '8px',
	objectFit: 'contain',
	'@media screen and (max-width: 600px)': {
		marginBottom: 0,
	},
});

interface ThreadDetailsProps {
	title: string;
	description?: string;
	detailsLeft: any;
	detailsRight: any;
}

export const ThreadDetails: React.FC<ThreadDetailsProps> = props => {
	const { title, description, detailsLeft, detailsRight, children } = props;

	return (
		<ThreadDetailsContainer>
			<HeaderLayout>
				<HeaderH3 style={{ hyphens: 'auto' }}>{title}</HeaderH3>
				<img src={seperatorIndicator} alt="separator"></img>
			</HeaderLayout>
			<StyledImage>{children}</StyledImage>
			<ThreadDetailsRow>
				<ThreadDetailsText>
					<Body>{description}</Body>
					{detailsLeft}
				</ThreadDetailsText>
				<ThreadDetailsText>
					<Body>{description}</Body>
					{detailsRight}
				</ThreadDetailsText>
			</ThreadDetailsRow>
		</ThreadDetailsContainer>
	);
};
