import * as React from 'react';
import styled from 'styled-components';

import { HeaderH3 } from './Typography';

import seperatorIndicator from '../svgs/seperator-indicator.svg';

const MatterFeaturesRoot = styled.div`
	margin-top: 5.5rem;
	margin-bottom: 6rem;
	display: flex;
	flex-direction: row;
	@media screen and (max-width: 600px) {
		flex-wrap: wrap;
	}
`;

const MatterFeaturesText = styled.div`
	margin-right: 1rem;
`;

interface ProductContainerProps {
	title: string;
}

export const MatterFeaturesContainer: React.FC<ProductContainerProps> = props => {
	const { title, children } = props;

	return (
		<MatterFeaturesRoot>
			<MatterFeaturesText>
				<HeaderH3 style={{ hyphens: 'auto' }}>{title}</HeaderH3>
				<img src={seperatorIndicator} alt="separator"></img>
				{children}
			</MatterFeaturesText>
		</MatterFeaturesRoot>
	);
};
