import * as React from 'react';
import styled from 'styled-components';

import { HeaderH3 } from './Typography';

import InterfacesImg from '../svgs/matter-interfaces.svg';
import seperatorIndicator from '../svgs/seperator-indicator.svg';

const InterfacesRoot = styled.div`
	margin-top: 5.5rem;
	margin-bottom: 6rem;
`;

const HeaderLayout = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	marginBottom: '2rem',
	width: '100%',

	'@media screen and (max-width: 600px)': {
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
	},
});

const ImageLayout = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	justify-content: space-around;
	text-align: center;
	flex-wrap: wrap;
`;

interface InterfacesContainerProps {
	title: string;
	description?: string;
}

export const InterfacesContainer: React.FC<InterfacesContainerProps> = props => {
	const { title } = props;

	return (
		<InterfacesRoot>
			<HeaderLayout>
				<HeaderH3 style={{ hyphens: 'auto' }}>{title}</HeaderH3>
				<img src={seperatorIndicator} alt="separator" />
			</HeaderLayout>
			<ImageLayout>
				<img src={InterfacesImg} width="700px" alt="solution image" />
			</ImageLayout>
		</InterfacesRoot>
	);
};
